import * as Arr from "Everlaw/Core/Arr";
import Button = require("Everlaw/UI/Button");
import { ColorTokens } from "design-system";
import Dom = require("Everlaw/Dom");
import * as Tooltip from "Everlaw/UI/Tooltip";
import Util = require("Everlaw/Util");
import dijit_focus = require("dijit/focus");
import dojo_on = require("dojo/on");
import type { Editor, TinyMCE, Ui } from "tinymce";

// Declaring a variable provided by the TinyMCE import in headHeader.jsp. This is included in all
// official typescript TinyMCE demos.
declare let tinymce: TinyMCE;

/**
 * Text editor using tinymce
 */
class TextEditor {
    editor: Editor = null;
    // Set to true after editor is rendered
    rendered: boolean;
    visible: boolean;
    customButton: TextEditor.CustomButtonParams;
    mceCustomButtonAPI: Ui.Toolbar.ToolbarToggleButtonInstanceApi;
    containerDiv: HTMLElement = null;
    private divId: string;
    private borders = true;
    private borderColor: string = ColorTokens.BORDER_SECONDARY;
    private initialContent: string;
    private minHeight = 66;
    private maxHeight: number;
    private autoResize = true;
    private listButtons = true;
    private extraButtons: boolean;
    private width = "100%";
    private showOnInit = true;
    private focusOnInit: boolean;
    private focusOnShow = true;
    private disableCustomButton: boolean;
    private onInit: () => void = () => {};
    private onCancel: () => void;
    private saveLabel = "Save";
    private cancelLabel = "Cancel";
    private onFocus: () => void = () => {};
    private onBlur: (fromSaveButton: boolean) => void = () => {};
    private onNodeChange: () => void = () => {};
    private onRender: () => void = () => {};
    private onChange: () => void = () => {};
    private onPostCustomButtonAdded: () => void = () => {};
    private clickedCustomButton = false;
    private isCancel = false;
    private focusOnce: boolean;
    private toDestroy: Util.Destroyable[] = [];
    constructor(params: TextEditor.Params) {
        Object.assign(this, params);
        // importcss allows the use of a custom css file to be used within the tinymce editor iframe
        // using the 'content_css' argument.
        let plugins = "importcss -unindentquotes";
        if (this.autoResize) {
            plugins += " autoresize";
        }
        let toolbar = "bold italic underline";
        if (this.listButtons) {
            plugins += " lists";
            toolbar += " | bullist numlist";
        }
        toolbar += " | removeformat";
        if (this.extraButtons) {
            toolbar += "  blockquote | alignleft aligncenter alignright alignjustify | undo redo";
        }
        if (this.customButton) {
            toolbar += " | " + this.customButton.name;
        }
        // Create container div, which also holds the Save and Cancel buttons if desired.
        this.containerDiv = Dom.div({
            id: this.divId + "-editor-container",
            style: {
                borderRadius: "4px",
                backgroundColor: ColorTokens.BACKGROUND_SECONDARY,
                height: "100%",
            },
        });
        Dom.create("div", { id: this.divId + "-anchor-div" }, this.containerDiv);
        if (this.borders) {
            Dom.style(this.containerDiv, "border", `${this.borderColor} 1px solid`);
        }
        const t = this;
        this.editor = new tinymce.Editor(
            this.divId + "-anchor-div",
            {
                branding: false,
                theme: "silver",
                resize: false,
                menubar: false,
                statusbar: false,
                content_css: "/ver/" + JSP_PARAMS.Versioning.assetVersion + "/css/tinymce.css",
                toolbar1: toolbar,
                autoresize_bottom_margin: 10,
                width: this.width,
                browser_spellcheck: true,
                height: this.minHeight || null,
                min_height: this.minHeight,
                max_height: this.maxHeight,
                plugins: plugins,
                // Disabling specific HTML elements for known security vulnerabilities.  These can be
                // removed after library is upgraded if needed.
                invalid_elements: "form,iframe,object,embed",
                setup: (editor) => {
                    if (this.customButton) {
                        const customButtonSpec: Ui.Toolbar.ToolbarToggleButtonSpec = {
                            onAction: () => {
                                this.clickedCustomButton = true;
                                this.customButton.onClick();
                            },
                            onSetup: function (api) {
                                t.mceCustomButtonAPI = api;
                                t.setCustomButtonDisabled(t.disableCustomButton);
                                t.onPostCustomButtonAdded();
                                return (api) => {};
                            },
                            // Including an icon parameter is necessary even though we replace it
                            // later (see replaceButtonsWithEverlawVersion(). If we don't, tinymce
                            // doesn't built out a button and there is no element to replace with
                            // our preferred button.
                            icon: "placeholder",
                            tooltip: this.customButton.tooltip,
                        };
                        // We can't do 'this.customButton.display || ""' since if we provide an empty
                        // string tinymce creates an unnecessary text element that messes up the button sizing
                        if (this.customButton.display) {
                            customButtonSpec.text = this.customButton.display;
                        }
                        editor.ui.registry.addToggleButton(
                            this.customButton.name,
                            customButtonSpec,
                        );
                    }
                },
                init_instance_callback: (editor) => {
                    // Replace default mce buttons with the ones we want
                    const toolbarOverlord: HTMLElement = editor
                        .getContainer()
                        .querySelector(".tox-toolbar-overlord");
                    const customMappings = this.customButton
                        ? new Map([[this.customButton.name, this.customButton.tooltip]])
                        : new Map();
                    const destroyableTooltips: Util.Destroyable[] =
                        TextEditor.replaceButtonsWithEverlawVersion(
                            toolbarOverlord,
                            [toolbar],
                            customMappings,
                        );
                    this.toDestroy.push(...destroyableTooltips);
                    TextEditor.purgeButtonTitles(toolbarOverlord);
                    TextEditor.makeMceToolbarTabsNavigable(editor.editorContainer);
                },
            },
            tinymce.EditorManager,
        );
        this.editor.on("change", () => this.onChange());
        this.editor.on("focus", () => this.onFocus());
        this.editor.on("blur", () => this._onBlur());
        this.editor.on("NodeChange", () => this.onNodeChange());
        this.editor.on("init", () => {
            dijit_focus.registerWin(this.editor.contentWindow, this.editor.container);
            Dom.style(this.editor.getContainer(), "border", "0");
            if (this.onCancel) {
                const buttonDiv = Dom.create(
                    "div",
                    {
                        id: this.divId + "-buttons",
                        class: "editor-buttons",
                        style: {
                            borderTopColor: this.borderColor,
                        },
                    },
                    this.containerDiv,
                );
                const cancelButton = new Button({
                    label: this.cancelLabel,
                    class: "skinny",
                    parent: buttonDiv,
                    width: "one",
                    onClick: this.onCancel,
                    makeFocusable: true,
                });
                const submitButton = new Button({
                    label: this.saveLabel,
                    class: "skinny safe important",
                    parent: buttonDiv,
                    width: "one",
                    onClick: () => this.onBlur(true),
                    makeFocusable: true,
                });
                dojo_on(cancelButton.node, "mouseenter", () => {
                    this.isCancel = true;
                });
                dojo_on(cancelButton.node, "mouseleave", () => {
                    this.isCancel = false;
                });
                this.toDestroy.push(cancelButton);
                this.toDestroy.push(submitButton);
            }
            if (this.showOnInit) {
                // The editor might think it's already "visible". If that's the case, calling
                // this.editor.show will not fire a show event (which we use to focus), so we'll
                // have to focus it ourselves.
                if (this.editor.isHidden()) {
                    this.editor.show();
                } else {
                    this._focusOnShow();
                }
                this.visible = true;
            } else {
                this.hide();
            }
            this.onInit();
        });
        this.editor.on("show", () => {
            this._focusOnShow();
        });
        Dom.setAriaLabel(this.containerDiv, params.ariaLabel);
    }
    blur() {
        if (this.visible) {
            this.editor.fire("blur");
        }
    }
    setCustomButtonDisabled(disabled: boolean) {
        this.disableCustomButton = disabled;
        if (this.mceCustomButtonAPI) {
            this.mceCustomButtonAPI.setEnabled(!disabled);
        }
    }
    private _focusOnShow() {
        // We always catch the event and check the condition inside in case focusOnShow gets
        // set after render has been called (but before it finishes).
        if (this.focusOnShow || this.focusOnce) {
            // This delay is necessary on (at least) Firefox. I can't for the life of me find
            // another way to deal with this problem. We trigger an immediate focus as well for
            // browsers that can handle it. It's also unclear whether 200ms is sufficient on all
            // hardware.
            setTimeout(() => {
                // Make sure the editor has not been destroyed in the meantime...
                if (this.editor) {
                    this.focus();
                }
            }, 200);
            this.focus();
            this.focusOnce = false;
        }
    }
    private _onBlur() {
        // don't call onCancel or onBlur when clicking the custom button
        if (this.clickedCustomButton) {
            this.clickedCustomButton = false;
            return;
        }
        if (this.visible) {
            if (this.isCancel) {
                this.onCancel();
            } else {
                this.onBlur(false);
            }
        }
        this.visible = false;
    }
    focus() {
        this.editor.focus();
        // Force a focus on the editor container, since dijit_focus otherwise rejects focus
        // events targeting body elements, which is the main dijit focus element.
        dijit_focus._onTouchNode(this.editor.contentDocument.body);
        this.onFocus();
    }
    // Returns true if initialization will happen as a result of this call.
    // runBeforeInit is a function that is only invoked if initialization is actually occurring.
    private init(runBeforeInit?: () => void, onInit?: () => void) {
        if (!this.rendered) {
            if (runBeforeInit) {
                runBeforeInit();
            }
            // Is the document element available now? If so, initialize immediately.
            const div = document.getElementById(this.divId);
            if (div) {
                if (div.parentElement) {
                    Dom.place(this.containerDiv, div.parentElement, "first");
                }
                if (onInit) {
                    this.editor.on("init", onInit);
                }
                this.editor.on("postRender", () => {
                    this.rendered = true;
                    this.onRender();
                });
                this.editor.render();
            } else {
                setTimeout(() => {
                    // The editor may have been destroyed before the timeout triggered.
                    if (this.editor) {
                        this.init(runBeforeInit, onInit);
                    }
                }, 1);
            }
            return true;
        }
        return false;
    }
    edit(content?: string) {
        if (
            !this.init(
                () => {
                    // If we need to initialize, make sure that editing happens right after.
                    this.showOnInit = true;
                    this.focusOnce = this.focusOnInit;
                },
                () => {
                    if (content) {
                        this.editor.setContent(content);
                    } else if (this.initialContent) {
                        this.editor.setContent(this.initialContent);
                    }
                },
            )
        ) {
            // already initialized
            this.show();
            this.editor.focus();
            this.onRender();
        }
    }
    hide() {
        this.visible = false;
        this.editor.hide();
        Dom.hide(this.containerDiv);
    }
    show() {
        if (
            !this.init(() => {
                this.showOnInit = true;
            })
        ) {
            // already initialized
            this.editor.show();
            this.visible = true;
            Dom.show(this.containerDiv);
        }
    }
    getValue(): string {
        if (!this.rendered) {
            return this.initialContent || "";
        }
        return this.editor.getContent();
    }
    setValue(value: string) {
        if (!this.rendered) {
            this.initialContent = value;
        } else {
            this.editor.setContent(value);
        }
    }
    destroy() {
        this.visible = false;
        if (this.editor !== null) {
            this.editor.remove();
            this.editor = null;
        }
        Util.destroy(this.toDestroy);
        this.toDestroy = [];
    }
}

module TextEditor {
    export interface Params {
        divId: string;
        initialContent?: string;
        ariaLabel?: string;
        /** Defaults to true. */
        borders?: boolean;
        /** Defaults to Color.borderPrimary. */
        borderColor?: string;
        /** Defaults to "100%". */
        width?: string;
        /** Defaults to true. */
        autoResize?: boolean;
        /** In px. Defaults to 66. */
        minHeight?: number;
        /** In px. Only used for auto-resize. */
        maxHeight?: number;
        /** Defaults to true. */
        showOnInit?: boolean;
        /** Defaults to false. */
        focusOnInit?: boolean;
        /** Defaults to true. */
        focusOnShow?: boolean;
        /** Adds justification and undo/redo buttons to the toolbar. Defaults to false. */
        extraButtons?: boolean;
        /** Adds bullet list and numeric list buttons to the toolbar. Defaults to true. */
        listButtons?: boolean;
        customButton?: CustomButtonParams;
        onInit?: () => void;
        /** If provided, creates save and cancel buttons below the editor. */
        onCancel?: () => void;
        /** Text on the save button. Only valid if onCancel is specified. Defaults to "Save". */
        saveLabel?: string;
        /** Text on the cancel button. Only valid if onCancel is specified. Defaults to "Cancel". */
        cancelLabel?: string;
        onFocus?: () => void;
        onBlur?: (fromSaveButton: boolean) => void;
        onNodeChange?: () => void;
        onRender?: () => void;
        onChange?: () => void;
        /** Called when _mceCustomButton is set. */
        onPostCustomButtonAdded?: () => void;
    }

    /**
     * Used for "Notes" button.
     *
     * If you want to add a new button, make sure there is an entry of the form mce-i-name in
     * _tinymce.scss following the example of similar entries.
     */
    export interface CustomButtonParams {
        /** All lower case, no spaces. */
        name: string;
        display?: string;
        onClick: () => void;
        tooltip: string;
    }

    export const mceButtonNameToTitle = new Map([
        ["bold", "Bold"],
        ["underline", "Underline"],
        ["italic", "Italic"],
        ["bullist", "Bullet list"],
        ["numlist", "Numbered list"],
        ["removeformat", "Clear formatting"],
        ["blockquote", "Blockquote"],
        ["alignleft", "Align left"],
        ["aligncenter", "Align center"],
        ["alignright", "Align right"],
        ["alignjustify", "Justify"],
        ["undo", "Undo"],
        ["redo", "Redo"],
    ]);

    /**
     * Replaces the default tinymce toolbar button icons with everlaw button icons. Also adds custom
     * tooltips to these buttons.
     *
     * Tinymce requires the creation of an icon pack to substitute default toolbar icons with custom
     * ones. "Icon packs" are directories of svgs with specific names that denote the icon they
     * correspond to. The icon pack would have to be updated every time an icon is changed, and the
     * names would possibly be different than the canonical icon names in the everlaw codebase.
     * Along with other complications, this means that we'd have to add another complex step to the
     * release checklist. As a result, it's simpler to substitute the icons with divs that have
     * backgrounds with the correct svgs.
     *
     * This function works by searching by title for the tinymce buttons, and then replacing the
     * icons with divs including the icons we want. Tinymce unfortunately does not give access to
     * its buttons, so using a selector is necessary.
     *
     * The return value is a list of tooltips to be destroyed when the TextEditor is closed.
     *
     * @param toolbarOverlord The div that contains the toolbar rows
     * @param toolbars The toolbar string, which contains words the names of buttons to be included
     * @param customButtonMapping For custom buttons that are added, a mapping of names to titles
     */
    export function replaceButtonsWithEverlawVersion(
        toolbarOverlord: HTMLElement,
        toolbars: string[],
        customButtonMapping: Map<string, string>,
    ): Util.Destroyable[] {
        const destroyableTooltips: Util.Destroyable[] = [];
        const modifiedMap = new Map([...mceButtonNameToTitle, ...customButtonMapping]);
        for (const toolbar of toolbars) {
            const buttonNames: string[] = toolbar.split(/[ |]+/);
            for (const buttonName of buttonNames) {
                const title = modifiedMap.get(buttonName);
                if (title) {
                    const button = toolbarOverlord.querySelector(
                        `button[title="${title}"]`,
                    ) as HTMLElement;
                    // If a button provided by the toolbar is not there for some reason , then it
                    // won't necessarily be found. So we wrap in this conditional to prevent null
                    // pointer exceptions in such cases.
                    if (button) {
                        button.children[0].replaceWith(Dom.div({ class: `mce-i-${buttonName}` }));
                        // Add custom tooltip to the button
                        destroyableTooltips.push(
                            new Tooltip(button, button.getAttribute("title") as string),
                        );
                    }
                }
            }
        }
        return destroyableTooltips;
    }

    /**
     * Tinymce adds titles to buttons, which activates the browsers default tooltip. We use our own
     * custom one so we remove the titles on each of the buttons.
     * @param toolbarOverlord The div that contains the toolbar rows
     */
    export function purgeButtonTitles(toolbarOverlord: HTMLElement) {
        const mceButtons = toolbarOverlord.querySelectorAll("button[title]");
        for (const button of mceButtons) {
            button.removeAttribute("title");
        }
    }

    /**
     * Takes in a tinymce editor container and makes the toolbar tab-navigable. The default tab
     * navigability uses tab to jump between toolbar groups and arrow keys to move within them, and
     * furthermore it tends to be buggy. In this function, we make it so that each toolbar element
     * can be tabbed to like any other elements on the page.
     *
     * In most cases, we simply make the
     * @param editorContainer Tinymce container with toolbars we want to make tab navigable.
     */
    export function makeMceToolbarTabsNavigable(editorContainer: HTMLElement) {
        // Get each toolbar - in some cases like the LegalHoldNoticeTextEditor, we use more than
        // one.
        const toolbars = Arr.fromArrayLike<Element>(
            editorContainer.getElementsByClassName("tox-toolbar"),
        );
        toolbars
            .filter((toolbar) => !!(toolbar && toolbar.children))
            .forEach((toolbar) => {
                const toolbarGroups = Arr.fromArrayLike<Node>(toolbar.childNodes);
                for (const group of toolbarGroups) {
                    const buttons = Arr.fromArrayLike<Node>(group.childNodes);
                    for (const button of buttons) {
                        // Tinymce sets to -1 by default, which prevents tab navigability, so we set
                        // it to 0 here to enable.
                        Dom.setAttr(button as HTMLElement, "tabindex", "0");
                        button.addEventListener("keydown", (e: KeyboardEvent) => {
                            if (e.key === "Tab") {
                                // Tinymce sets buttons' tab index to -1, then uses built in event
                                // listeners to create their desired tab functionality, we don't
                                // want that. The events tinymce uses are attached to the container
                                // element to we stop propagation so it doesn't get there.
                                e.stopPropagation();
                                // To ensure we don't run into problems with ancestors of the
                                // tinymce container responding to an event, we redispatch the event
                                // with the same parameters in the container parent.
                                editorContainer.parentNode.dispatchEvent(
                                    new KeyboardEvent("keydown", {
                                        key: "Tab",
                                        code: "Tab",
                                        shiftKey: e.shiftKey,
                                    }),
                                );
                            }
                        });
                    }
                }
            });
    }
}

export = TextEditor;
