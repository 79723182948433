/**
 * Matches CanonicalField.java.
 */
export enum Canonical {
    ALL_CUSTODIANS = "All Custodians",
    ALL_PATHS = "All Paths",
    APPLICATION = "Application",
    ATTACHMENT_IDS = "Attachment Ids",
    ATTACHMENT_NAMES = "Attachment Names",
    AUTHOR = "Author",
    BCC = "Bcc",
    BEGIN_FAMILY = "Begin Family",
    CC = "Cc",
    CHAT_CONTRIBUTORS = "Chat Contributors",
    CHAT_CONVERSATION_ID = "Chat Conversation Id",
    CHAT_CONVERSATION_INDEX = "Chat Conversation Index",
    CONFIDENTIALITY = "Confidentiality",
    CUSTODIAN = "Custodian",
    CUSTOM_ALL_CUSTODIANS = "Custom All Custodians",
    DATASET = "Dataset",
    DATA_TYPE = "Data Type",
    DATE = "Date",
    DATE_ACCESSED = "Date Accessed",
    DATE_CREATED = "Date Created",
    DATE_MODIFIED = "Date Modified",
    DATE_PRINTED = "Date Printed",
    DATE_RECEIVED = "Date Received",
    DATE_SAVED = "Date Saved",
    DATE_SENT = "Date Sent",
    DOCUMENT_TYPE = "Document Type",
    ENCRYPTED = "Encrypted",
    END_DATE = "End Date",
    END_FAMILY = "End Family",
    ENDORSED_TEXT = "Endorsed Text",
    EXTENSION = "Extension",
    FAMILY = "Family",
    FAMILY_RANGE = "Family Range",
    FILENAME = "Filename",
    FILE_PATH = "File Path",
    FROM = "From",
    GPS_LATITUDE = "GPS Latitude",
    GPS_LONGITUDE = "GPS Longitude",
    HASH_VALUE = "Hash Value",
    HAS_OCR = "Has OCR",
    HIDDEN_CONTENT = "Hidden Content",
    IN_REPLY_TO = "In Reply To",
    LANGUAGES = "Languages",
    MD5_HASH = "MD5 Hash",
    MESSAGE_ID = "Message Id",
    MIME_TYPE = "Mime Type",
    ORIGINAL_FILENAME = "Original Filename",
    ORIGINAL_PATH = "Original Path",
    OTHER_BATES = "Other Bates",
    OTHER_CUSTODIANS = "Other Custodians",
    PARENT_ID = "Parent Id",
    PLACEHOLDER = "Placeholder",
    PLACEHOLDER_TEXT = "Placeholder Text",
    PRIVILEGE_TYPE = "Privilege Type",
    PRODUCED_FROM = "Produced From",
    REDACTED = "Redacted",
    REDACTION_STAMPS = "Redaction Stamps",
    REDACTION_STAMP_DETAILS = "Redaction Stamp Details",
    SHA1_HASH = "SHA1 Hash",
    SPEAKER_NOTES = "Speaker Notes",
    SPLIT_FROM = "Split From",
    START_DATE = "Start Date",
    SUBJECT = "Subject",
    TAGS = "Tags",
    TICK_NO = "Tick No",
    TITLE = "Title",
    TO = "To",
    TRACK_CHANGES = "Track Changes",
    TRANSLATION_OF = "Translation Of",
}
