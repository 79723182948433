/**
 * Keys that identify each viewer.
 */
const enum View {
    CHAT = "Chat",
    EMPTY = "Empty",
    IMAGE = "Image",
    MEDIA = "Media",
    NATIVE = "Native",
    NATIVE_IMAGE = "NativeImage",
    PDF = "PDF",
    SPREADSHEET = "Spreadsheet",
    TEXT = "Text",
}

export default View;
