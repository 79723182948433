import { PANEL_DEFAULT_WIDTH } from "Everlaw/Review/FullScreenConstants";
import { PanelLayout } from "Everlaw/Review/FullScreenTypes";

export const ANNOTATIONS_NAME = "Annotations";
export const CHAT_NAME = "Chat";
export const TASKS_NAME = "Tasks";
export const EXHIBITS_NAME = "Exhibits";
// This module used to contain only documents. Changing the value here breaks existing preferences.
export const EVIDENCE_NAME = "Documents";
export const SEARCH_NAME = "Search";

// The order here needs to correspond to the Deposition.State fspIndex.
export const defaultLayout: PanelLayout[] = [
    {
        name: "Deposition",
        width: PANEL_DEFAULT_WIDTH,
        groups: [
            {
                heightBasis: 0.5,
                modules: [EVIDENCE_NAME, TASKS_NAME],
                active: EVIDENCE_NAME,
            },
            {
                heightBasis: 0.5,
                modules: [CHAT_NAME],
                active: CHAT_NAME,
            },
        ],
        expanded: -1,
    },
    {
        name: "Summary",
        width: PANEL_DEFAULT_WIDTH,
        groups: [
            {
                heightBasis: 0.5,
                modules: [TASKS_NAME],
                active: TASKS_NAME,
            },
            {
                heightBasis: 0.5,
                modules: [CHAT_NAME],
                active: CHAT_NAME,
            },
        ],
        expanded: -1,
    },
    {
        name: "Transcript",
        width: PANEL_DEFAULT_WIDTH,
        groups: [
            {
                heightBasis: 0.5,
                modules: [EVIDENCE_NAME, SEARCH_NAME, TASKS_NAME],
                active: EVIDENCE_NAME,
            },
            {
                heightBasis: 0.5,
                modules: [CHAT_NAME, ANNOTATIONS_NAME],
                active: CHAT_NAME,
            },
        ],
        expanded: -1,
    },
];

const chatModuleInfo = { name: CHAT_NAME, icon: "user" };
const tasksModuleInfo = { name: TASKS_NAME, icon: "check" };
const evidenceModuleInfo = { name: EVIDENCE_NAME, display: "Evidence", icon: "file" };
const searchModuleInfo = { name: SEARCH_NAME, icon: "search" };
// if we ever use config panel on depo page, this will need an icon
const annotationsModuleName = { name: ANNOTATIONS_NAME, display: "Highlights", icon: "" };

export const transcriptViewOnlyModuleList = [
    evidenceModuleInfo,
    searchModuleInfo,
    annotationsModuleName,
];

export const moduleList = [
    chatModuleInfo,
    tasksModuleInfo,
    evidenceModuleInfo,
    searchModuleInfo,
    annotationsModuleName,
];
