import Base = require("Everlaw/Base");
import Category = require("Everlaw/Category"); // Circular, types only
import Code = require("Everlaw/Code"); // Circular, types only
import Project = require("Everlaw/Project");
import Rest = require("Everlaw/Rest");

class CodeOrder extends Base.Object {
    get className() {
        return "CodeOrder";
    }
    projectId: Project.Id;
    override id: CodeOrder.Id; // This is called categoryId on the backend, but Base needs an EBO to have an id
    sequence: number[];
    alphabetical: boolean;
    constructor(params: any) {
        super(params);
        this._mixin(params);
    }
    override _mixin(params: any) {
        Object.assign(this, params);
    }
    getCategory(): Category | null {
        return this.isCategoryOrder() ? null : Base.get("Category", this.id);
    }
    getSequence(): Category[] | Code[] {
        // Using Base.get with a string to work around the circular dependency
        return this.isCategoryOrder()
            ? <Category[]>Base.get("Category", this.sequence)
            : <Code[]>Base.get("Code", this.sequence);
    }
    isCategoryOrder() {
        return this.id === CodeOrder.CATEGORY_ORDER_ID;
    }
    alphabetize() {
        return Rest.post("categories/alphabetize.rest", { categoryId: this.id }).then((data) =>
            Base.set(CodeOrder, data),
        );
    }
    reorder(newOrder: number[]) {
        return Rest.post("categories/reorder.rest", { categoryId: this.id, newOrder }).then(
            (data) => Base.set(CodeOrder, data),
        );
    }
}

module CodeOrder {
    export type Id = number & Base.Id<"CodeOrder">;

    export const CATEGORY_ORDER_ID = 0;
}

export = CodeOrder;
