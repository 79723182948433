import TextVariant from "Everlaw/Review/DifferenceViewer/TextVariant";

export interface TextVariantElement {
    variant: TextVariant;
    element: HTMLElement;
}

/**
 *  Provides sorting options for text variants, wrapped in getSortOrder to sort the reference
 *  document first. Default sorting option is longest-text-first.
 *  TODO V1 - Add sorting option for metadata (date)
 */

export enum SortType {
    TEXT_LENGTH_DESC,
    TEXT_LENGTH_ASC,
    DOC_COUNT_DESC,
    DOC_COUNT_ASC,
    TEXT_ALPHA_ASC,
    TEXT_ALPHA_DESC,
}

export class TextVariantSort {
    public readonly type: SortType;
    public readonly label: string;
    private readonly fnc: (a: TextVariantElement, b: TextVariantElement) => number;

    private constructor(
        type: SortType,
        label: string,
        isDesc: boolean,
        compareFunc: (a: TextVariantElement, b: TextVariantElement) => number,
    ) {
        this.type = type;
        this.label = label;
        this.fnc = TextVariantSort.createSortFunction(compareFunc, isDesc);
    }

    public sort(elements: TextVariantElement[]) {
        elements.sort(this.fnc);
    }

    static TEXT_LENGTH_DESC: TextVariantSort = new TextVariantSort(
        SortType.TEXT_LENGTH_DESC,
        "Text length (long to short)",
        true,
        (a: TextVariantElement, b: TextVariantElement) =>
            a.variant.text.length - b.variant.text.length,
    );
    static TEXT_LENGTH_ASC: TextVariantSort = new TextVariantSort(
        SortType.TEXT_LENGTH_ASC,
        "Text length (short to long)",
        false,
        (a: TextVariantElement, b: TextVariantElement) =>
            a.variant.text.length - b.variant.text.length,
    );
    static DOC_COUNT_DESC: TextVariantSort = new TextVariantSort(
        SortType.DOC_COUNT_DESC,
        "Documents (most to fewest)",
        true,
        (a: TextVariantElement, b: TextVariantElement) =>
            a.variant.docIds.length - b.variant.docIds.length,
    );
    static DOC_COUNT_ASC: TextVariantSort = new TextVariantSort(
        SortType.DOC_COUNT_ASC,
        "Documents (fewest to most)",
        false,
        (a: TextVariantElement, b: TextVariantElement) =>
            a.variant.docIds.length - b.variant.docIds.length,
    );
    static TEXT_ALPHA_ASC: TextVariantSort = new TextVariantSort(
        SortType.TEXT_ALPHA_ASC,
        "Text content (A to Z)",
        false,
        (a: TextVariantElement, b: TextVariantElement) =>
            a.variant.text.localeCompare(b.variant.text),
    );
    static TEXT_ALPHA_DESC: TextVariantSort = new TextVariantSort(
        SortType.TEXT_ALPHA_DESC,
        "Text content (Z to A)",
        true,
        (a: TextVariantElement, b: TextVariantElement) =>
            a.variant.text.localeCompare(b.variant.text),
    );

    static sorts = [
        TextVariantSort.TEXT_LENGTH_DESC,
        TextVariantSort.TEXT_LENGTH_ASC,
        TextVariantSort.DOC_COUNT_DESC,
        TextVariantSort.DOC_COUNT_ASC,
        TextVariantSort.TEXT_ALPHA_ASC,
        TextVariantSort.TEXT_ALPHA_DESC,
    ];

    static DEFAULT = TextVariantSort.TEXT_LENGTH_DESC;

    /**
     * Sorts TextVariantElements while keeping the reference variant at the front, utilized in all sorting options.
     * isDesc param determines whether the sort should be descending.
     */
    private static createSortFunction(
        sortOrder: (a: TextVariantElement, b: TextVariantElement) => number,
        isDesc = false,
    ): (a: TextVariantElement, b: TextVariantElement) => number {
        return (a: TextVariantElement, b: TextVariantElement) => {
            if (a.variant.isReference) {
                return -1;
            } else if (b.variant.isReference) {
                return 1;
            }
            const multiplier = isDesc ? -1 : 1;
            return multiplier * sortOrder(a, b);
        };
    }
}
