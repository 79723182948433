import Context = require("Everlaw/Context/Context");
import { Context as DatabaseContext } from "Everlaw/Database";
import Project = require("Everlaw/Project");

export = new (class extends Context {
    constructor() {
        super(DatabaseContext.ECA);
    }

    public override inServerContext() {
        return false;
    }

    public override inProjectContext(project: Project): boolean {
        return super.inProjectContext(project) && project.eca;
    }

    public override canElevatedRolesOverride(): boolean {
        return false;
    }
})();
