import Eca = require("Everlaw/Context/Eca");
import ServiceProvider = require("Everlaw/ServiceProvider");
import { GroupLayout, PanelLayout } from "Everlaw/Review/FullScreenTypes";

// Widget names used for registration and display
// Warning: renaming or removing one of these will currently break Full Screen mode for users who
// have it in the preference set.
// TODO: decouple module registration name from display name so that the latter can be changed if
// necessary (E.g. Language -> Languages)

// This switching for CODES_NAME depending on context is possible because ECA and regular Everlaw use different prefs
// for layout. If you want to have different names depending on other contexts, you will have to fulfill the todo above.
export const CODES_NAME = Eca.inContext() ? "Binders" : "Codes";
export const CODING_PRESETS_NAME = "Coding Presets";
export const HIT_HIGHLIGHTS_NAME = "Hit Highlights";
export const LANGUAGE_NAME = "Language";
export const PANEL_DEFAULT_WIDTH = 320;
export const PANEL_MIN_WIDTH = 300;
export const PREDICTIVE_CODING_NAME = "Predictive Coding";
export const METADATA_NAME = "Metadata";
export const ANNOTATIONS_NAME = "Annotations";
export const HISTORY_NAME = "History";
export const STORYBUILDER_NAME = "Storybuilder";
export const TOOLBAR_NAME = "Toolbar";

// Any reference to a SavedReviewLayout with an id of -1 is referencing this default layout.
// E.g. this can be referenced by an AssignmentGroup or in the hiddenReviewLayouts preference.
export const DEFAULT_NAME = "Project default layout";
export const DEFAULT_ID = -1;

let groups: GroupLayout[];

if (Eca.inContext()) {
    groups = [
        {
            heightBasis: 0.5,
            modules: [CODES_NAME, METADATA_NAME],
            active: CODES_NAME,
        },
        {
            heightBasis: 0.5,
            ...(ServiceProvider.TRANSLATION.isEnabled()
                ? {
                      modules: [LANGUAGE_NAME, HIT_HIGHLIGHTS_NAME],
                      active: LANGUAGE_NAME,
                  }
                : {
                      modules: [HIT_HIGHLIGHTS_NAME],
                      active: HIT_HIGHLIGHTS_NAME,
                  }),
        },
    ];
} else {
    groups = [
        {
            heightBasis: 0.49,
            modules: [CODES_NAME, METADATA_NAME, HIT_HIGHLIGHTS_NAME],
            active: CODES_NAME,
        },
        {
            heightBasis: 0.49,
            ...(ServiceProvider.TRANSLATION.isEnabled()
                ? {
                      modules: [ANNOTATIONS_NAME, LANGUAGE_NAME],
                      active: LANGUAGE_NAME,
                  }
                : {
                      modules: [ANNOTATIONS_NAME],
                      active: ANNOTATIONS_NAME,
                  }),
        },
        {
            heightBasis: 0.02,
            modules: [CODING_PRESETS_NAME, STORYBUILDER_NAME],
            active: CODING_PRESETS_NAME,
        },
    ];
}

export const defaultLayout: PanelLayout[] = [
    {
        name: DEFAULT_NAME,
        width: PANEL_DEFAULT_WIDTH,
        groups,
        expanded: -1,
        savedId: DEFAULT_ID,
    },
];

export const moduleList = [
    { name: CODES_NAME, icon: "flags" },
    { name: CODING_PRESETS_NAME, icon: "parking", isAccessible: () => !Eca.inContext() },
    { name: HIT_HIGHLIGHTS_NAME, icon: "input-search" },
    { name: LANGUAGE_NAME, icon: "language" },
    { name: PREDICTIVE_CODING_NAME, icon: "filter", isAccessible: () => !Eca.inContext() },
    { name: METADATA_NAME, icon: "file-info" },
    { name: ANNOTATIONS_NAME, icon: "note", isAccessible: () => !Eca.inContext() },
    { name: HISTORY_NAME, icon: "history", hideSettings: true },
    {
        name: STORYBUILDER_NAME,
        icon: "story-feather",
        hideSettings: true,
        isAccessible: (): boolean => !Eca.inContext(),
    },
];
