// Code as previous settings and helper functions
import Arr = require("Everlaw/Core/Arr");

// A CaPConfig element that is a subset of another element should denote the parent element,
// and the parent element should denote the child element. This will render the child option
// as a checkbox which is only enabled if the parent radio option is selected.
export interface CaPConfig {
    id: any;
    display: string;
    child?: string;
    parent?: string;
}

export enum SourcesMode {
    VIEWED = "viewed state",
    CHANGES = "changes",
    MODIFIED = "modified state",
}

// state: Apply all codes and the rating on the antecedent document (viewed or modified)
// changes: Apply the changes that the user made to the antecedent document
export const sourcesConfig: CaPConfig[] = [
    {
        id: SourcesMode.VIEWED,
        display: "Last viewed document",
    },
    {
        id: SourcesMode.CHANGES,
        display: "Apply only new codes instead of all codes",
        parent: SourcesMode.MODIFIED,
    },
    {
        id: SourcesMode.MODIFIED,
        display: "Last modified document",
        child: SourcesMode.CHANGES,
    },
];

export var sources = sourcesConfig.map((s) => s.id);

export enum RatingMode {
    APPLY_TO_UNRATED = "applyToUnrated",
    ALWAYS = "alwaysRating",
    IGNORE = "ignoreRating",
}

export const ratingConfig: CaPConfig[] = [
    {
        id: RatingMode.APPLY_TO_UNRATED,
        display: "Only if current document is Unrated",
        parent: RatingMode.ALWAYS,
    },
    {
        id: RatingMode.ALWAYS,
        display: "Apply rating",
        child: RatingMode.APPLY_TO_UNRATED,
    },
    {
        id: RatingMode.IGNORE,
        display: "Never apply rating",
    },
];

export enum FFCMode {
    APPLY_TO_DOCS_WITH_NO_VALUE = "applyToDocsWithNoValue",
    ALWAYS = "alwaysCode",
    IGNORE = "neverCode",
}

export const ffcConfig: CaPConfig[] = [
    {
        id: FFCMode.APPLY_TO_DOCS_WITH_NO_VALUE,
        display: "Only if current document has no value for that freeform code",
        parent: FFCMode.ALWAYS,
    },
    {
        id: FFCMode.ALWAYS,
        display: "Apply freeform code",
        child: FFCMode.APPLY_TO_DOCS_WITH_NO_VALUE,
    },
    {
        id: FFCMode.IGNORE,
        display: "Never apply freeform code",
    },
];

// default source is "viewed state"
export var defaultSource = SourcesMode.VIEWED;

// default rating setting is "apply to unrated"
export var defaultRatingMode = RatingMode.APPLY_TO_UNRATED;

// default freeform codes setting is "Apply Freeform Code"
export const defaultFreeformCodesMode = FFCMode.ALWAYS;

// Returns true if the given string is a valid Code as previous source; false otherwise
export function isSource(source: string) {
    return Arr.contains(sources, source);
}

// Returns true if the given string is a source that uses the last modified document (as opposed to
// last viewed) as its source; false otherwise (including if the given string is not a source)
export function usesLastModifiedDoc(source: string) {
    return source === SourcesMode.MODIFIED || source === SourcesMode.CHANGES;
}

// Returns true if the given string is a source that uses the state (as opposed to changes) of the
// antecedent document; false otherwise (including if the given string is not a source)
export function usesState(source: string) {
    return source === SourcesMode.MODIFIED || source === SourcesMode.VIEWED;
}

export function isRatingMode(ratingMode: string) {
    return Arr.contains(
        ratingConfig.map((rm) => rm.id),
        ratingMode,
    );
}

export function isFreeformCodesMode(freeformCodesMode: string) {
    return Arr.contains(
        ffcConfig.map((fm) => fm.id),
        freeformCodesMode,
    );
}
