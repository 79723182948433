import Base = require("Everlaw/Base");
import DocumentGroupFilter = require("Everlaw/DocumentGroupFilter");
import * as Project from "Everlaw/Project";
import { NearDupeInclusionCriteria } from "Everlaw/Project";

class DocumentGroupType extends Base.Object {
    private static DefaultFilters = [
        DocumentGroupFilter.PluckChildren,
        DocumentGroupFilter.PluckParent,
        DocumentGroupFilter.SearchHits,
        DocumentGroupFilter.GroupedHits,
    ];
    private static EmailDuplicateFilters = [
        DocumentGroupFilter.PluckChildren,
        DocumentGroupFilter.PluckParent,
        DocumentGroupFilter.SearchHits,
        DocumentGroupFilter.GroupedHits,
        DocumentGroupFilter.ExactEmailDuplicates,
    ];
    private static ThreadingFilters = [
        DocumentGroupFilter.PluckParent,
        DocumentGroupFilter.Attachments,
        DocumentGroupFilter.SearchHits,
        DocumentGroupFilter.GroupedHits,
        DocumentGroupFilter.InclusiveEmails,
    ];
    private static ChatConversationFilters = [
        DocumentGroupFilter.PluckParent,
        DocumentGroupFilter.Attachments,
        DocumentGroupFilter.SearchHits,
        DocumentGroupFilter.GroupedHits,
    ];
    static NoGroup = DocumentGroupType.init("null", "No grouping", "Documents", "Documents", [], 7);
    static Attachments = DocumentGroupType.init(
        "Attachments",
        "Attachments",
        "Att groups",
        "Attachment groups",
        DocumentGroupType.DefaultFilters,
        0,
    );
    static Threading = DocumentGroupType.init(
        "Threading",
        "Email threads",
        "Threads",
        "Email threads",
        DocumentGroupType.ThreadingFilters,
        1,
    );
    static ChatConversations = DocumentGroupType.init(
        "ChatConversations",
        "Chat conversations",
        "Conversations",
        "Chat conversations",
        DocumentGroupType.ChatConversationFilters,
        2,
    );
    static AllConversations = DocumentGroupType.init(
        "AllConversations",
        "Email and chat conversations",
        "Conversations",
        "Email and chat conversations",
        DocumentGroupType.ChatConversationFilters,
        3,
    );
    static ExactDuplicates = DocumentGroupType.init(
        "ExactDuplicates",
        DocumentGroupType.getDuplicateGroupString(),
        "Dupe groups",
        "Duplicate groups",
        Project.CURRENT?.emailDedupe
            ? DocumentGroupType.EmailDuplicateFilters
            : DocumentGroupType.DefaultFilters,
        4,
    );
    static NearDuplicates = DocumentGroupType.init(
        "NearDuplicates",
        DocumentGroupType.nearDupesDisplay(),
        "Near dupe groups",
        "Near duplicate groups",
        DocumentGroupType.DefaultFilters,
        5,
    );
    static Versions = DocumentGroupType.init(
        "Versions",
        "Versions",
        "Version groups",
        "Version groups",
        DocumentGroupType.DefaultFilters,
        6,
    );
    private static groupMap = DocumentGroupType.generateGroupMap();

    private static init(
        id: string,
        menuText: string,
        unit: string,
        unitFull: string,
        filters: DocumentGroupFilter[],
        displayOrder: number,
    ) {
        const groupType = new DocumentGroupType(
            id,
            menuText,
            unit,
            unitFull,
            filters,
            displayOrder,
        );
        Base.add(groupType);
        return groupType;
    }
    static getDuplicateGroupString(): string {
        return Project.CURRENT?.emailDedupe ? "Exact and email duplicates" : "Exact duplicates";
    }

    static nearDupesDisplay(): string {
        const criteria =
            Project.CURRENT?.nearDupeInclusionCriteria || NearDupeInclusionCriteria.ONLY_TEXT;
        switch (criteria) {
            case NearDupeInclusionCriteria.EXACT_AND_TEXT:
                return "Near and exact duplicates";
            case NearDupeInclusionCriteria.EXACT_AND_EMAIL_AND_TEXT:
                return "Near, exact, and email duplicates";
            case NearDupeInclusionCriteria.ONLY_TEXT:
                return "Near duplicates";
            default:
                throw Error(criteria);
        }
    }

    get className() {
        return "DocumentGroupType";
    }
    override id: DocumentGroupType.Id;
    protected constructor(
        id: string,
        public menuText: string,
        public unit: string,
        public unitFull: string,
        public filters: DocumentGroupFilter[] = [],
        public displayOrder: number,
    ) {
        super({ id });
        if (!this.filters.includes(DocumentGroupFilter.NoFilter)) {
            this.filters.unshift(DocumentGroupFilter.NoFilter);
        }
    }
    override display() {
        return this.menuText;
    }
    override compare(other) {
        if (other.className === "DocumentGroupType") {
            return this.displayOrder - other.displayOrder;
        }
        return super.compare(other);
    }
    static getGroupTypeFromName(name: string) {
        if (name.startsWith("Exact")) {
            // In defaultGrouping preference, display() is the keyword persisted in db.
            // For ExactDuplicates, the persisted string could be "Exact duplicates" or
            // "Exact and email duplicates", depending on project email-dedupe setting.
            name = this.getDuplicateGroupString();
        } else if (name.startsWith("Near")) {
            // Similarly, default group preference may be any of strings in this.nearDupesDisplay()
            // depending on inclusionCriteria when preference was set. Convert to the display of
            // the current inclusionCriteria to access the group from the map.
            name = this.nearDupesDisplay();
        }
        if (Object.keys(this.groupMap).includes(name)) {
            return this.groupMap[name];
        }
    }
    private static generateGroupMap() {
        const groupTypeMap: { [display: string]: DocumentGroupType } = {};
        for (const name of Object.keys(DocumentGroupType)) {
            const ref = DocumentGroupType[name];
            if (typeof ref == "object" && ref.className === "DocumentGroupType") {
                groupTypeMap[ref.display()] = ref;
            }
        }
        return groupTypeMap;
    }
}

module DocumentGroupType {
    export type Id = string & Base.Id<"DocumentGroupType">;
}

export = DocumentGroupType;
